
import router from "@/router"
import axios from "axios"
import { Component, Vue } from 'vue-property-decorator';
import HeaderLayout from "@/components/layout/HeaderLayout.vue";
import BannerLayout from "@/components/layout/BannerLayout.vue"
import InfoLayout from "@/components/layout/InfoLayout.vue";
import FooterLayout from "@/components/layout/FooterLayout.vue";
import SupportLayout from "@/components/layout/SupportLayout.vue";
import Survey1Layout from "@/components/layout/Survey1Layout.vue";
import PopupBannerLayout2 from "@/components/layout/PopupBannerLayout2.vue";

@Component({
    components: {
        FooterLayout,
        InfoLayout,
        HeaderLayout,
        BannerLayout,
        SupportLayout,
        Survey1Layout,
        PopupBannerLayout2,
    },
})
export default class App extends Vue {
    async created(): Promise<void> {
        try {
            const utmParams = this.getUtmParamsFromUrl();
            this.saveUtmParamsToLocalStorage(utmParams);

            this.$store.commit('SET_INIT_LOADING', true)
            this.$store.commit('SET_LOADING', true);

            await Promise.all<void>([
                this.$store.dispatch('setHash'),
                this.$store.dispatch('setConfig')
            ])
                .then(() => {
                    axios.interceptors.response.use(
                        async (res) => {
                            const originalRequest = res.config;
                            // @ts-expect-error whatever
                            if (res.status === 401 && !originalRequest._retry) {
                                // @ts-expect-error whatever
                                originalRequest._retry = true;

                                const resRefresh = await this.$store.dispatch('Auth/refreshToken', originalRequest)
                                return resRefresh ?
                                    resRefresh :
                                    res
                            }

                            return res
                        }
                    )
                })

            switch (await router.currentRoute.name) {
                case 'passwordReset':
                case 'verifyEmail':
                case 'invitations':
                case 'register':
                case 'offer':
                case 'HowToUse':
                case 'policy':
                case 'tariffs':
                case 'zakaz':
                case 'usluga':
                case 'kontakt':
                case 'listofservices':
                case 'avito-auth':
                    return
                default:
                    await this.$store.dispatch('authenticate', true)
            }
        } catch (err) {
            console.error(err)
        } finally {
            this.$store.commit('SET_INIT_LOADING', false)
            this.$store.commit('SET_LOADING', false)
        }
    }

    private getUtmParamsFromUrl(): Record<string, string> {
        const urlParams = new URLSearchParams(window.location.search);
        const utmParams: Record<string, string> = {};

        for (const [key, value] of urlParams.entries()) {
            if (key.startsWith('utm_')) {
                utmParams[key] = value;
            }
        }

        return utmParams;
    }

    private saveUtmParamsToLocalStorage(utmParams: Record<string, string>): void {
        const storedUtmParamsString = localStorage.getItem('utmParams');
        let storedUtmParams: Record<string, string> = {};

        if (storedUtmParamsString) {
            try {
                storedUtmParams = JSON.parse(storedUtmParamsString);
            } catch (error) {
                console.error('Error parsing stored utmParams:', error);
            }
        }

        storedUtmParams = { ...storedUtmParams, ...utmParams };

        localStorage.setItem('utmParams', JSON.stringify(storedUtmParams));
    }
}
